'use client'
//mport VTasks from './vtasks/VTasks'

import Head from 'next/head';







export function Metadata({ title = 'Interwin', description = 'Encontrá tu lugar', imageUrl = '/img/iwin-preview.jpg' }) {
 
  
   
  
  return (
    <>


    <meta charSet="utf-8" />
    <link rel="icon" href="/favicon.ico"  type="image/x-icon" crossorigin="anonymous"/>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta
      name="description"
      content={description}
    />
      <meta
      name="title"
      content={title}
    />
    <meta name="theme-color" content="#000000" />
    <meta property="og:type" content="website" />

    <meta property="og:title" content={title} />
    <meta property="og:image" content={imageUrl} />
    <meta property="og:description" 
  content={description} />
<meta property="og:image:type" content="image/jpg" />
<meta property="og:image:width" content="220" />
<meta property="og:image:height" content="220" />


   
    <link
      href="https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i"
      rel="stylesheet" 
    />
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link
      href="https://fonts.googleapis.com/css2?family=Abel&family=Big+Shoulders+Text:wght@100;300;400;500;600;700;800&family=Gidugu&family=Kufam:wght@400;500;600;700&display=swap"
      rel="stylesheet" 
    />
<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"></link>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css"  crossOrigin="anonymous"/>

    
<link href="https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet" />
<title>{title}</title>
</>
)}
