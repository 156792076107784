'use client';

import { CacheProvider } from '@chakra-ui/next-js';
import { CSSReset, ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import FilterContext from '@/components/FilterContext';
import { extendTheme } from '@chakra-ui/react'
import { useState,useEffect } from 'react';
import { useRouter } from 'next/router';
import { usePathname } from 'next/navigation';
//import ChatBot from '@/components/ChatBot';


export function Providers({ children }) {
  const [filtersContext, setFiltersContext] = useState(null)



  /*function ConditionalChatBot() {
    const pathname = usePathname();
    const isRootOrSingleLevel = /^\/([^\/]+)?$/.test(pathname);
  
    return isRootOrSingleLevel ? <ChatBot /> : null;
  }
*/
 
const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  styles: {
    global: {
      body: {
        color: 'black',
        bg: 'white',
      },
    },
  },
});
  return (

    <FilterContext.Provider value={[filtersContext, setFiltersContext]}>
    <CacheProvider>
      <ChakraProvider theme={theme}>
      <CSSReset />
        <ColorModeScript
        initialColorMode='light'/>
        {children}</ChakraProvider>
    
    </CacheProvider>
    </ FilterContext.Provider>


  );
}
